const reducer = (state, type) => {
    
    switch(type.action){
        
        case "SET_API_DATA":
            return {
                ...state,
                loading: false, 
                filterArticles: type.payload.dataArticles,
                filterVideos: type.payload.dataVideos,
                adsData: type.payload.dataAds,
                filterAllArticleVideo: type.payload.dataAllArticleVideo,
            }
        case "SET_API_DATA_1":
            return {
                ...state,
                filterAllArticleVideo: type.payload.filterAllArticleVideo, 
                filterVideos: type.payload.filterVideos, 
            }      
        default:
            return state;        
    }
}

export default reducer;