import React from 'react';
import lnkIcon from '../img/linkedin-icon-1blue-100x100.png';
import messageIcon from '../img/message-icon.png';
const Footer = (props) => {
    return (
        <>
            <div className='query-btn'>
               <a  href='https://www.pharmacompass.com/share-a-request' target='_blank'>
               <img src={messageIcon} className='imgPstEnq' alt='message icon' />
                <p>ASK US!</p>
               </a>
                
            </div>
            <div className="footerArea">
                <div className="footerAreainner">
                    <div className="footerMenu">
                        <a href='https://www.pharmacompass.com/' target='_blank' rel="noopener noreferrer">Privacy Policy</a>
                        <a href='https://www.pharmacompass.com/' target='_blank' rel="noopener noreferrer">Terms and conditions</a>
                        <a href='https://www.pharmacompass.com/' target='_blank' rel="noopener noreferrer">Disclaimers</a>
                        <a href='https://www.pharmacompass.com/' target='_blank' rel="noopener noreferrer"><img className="lnkImg" src={lnkIcon} alt="Linkedin" /></a>
                    </div>
                    <div className="copyRight">
                        Copyright © 2023 LePro PharmaCompass OPC Private Limited, All rights reserved.
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;