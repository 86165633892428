// import {React} from 'react';
import React, { useEffect, useState } from "react";
import VideoGrid from "../Components/VideoGrid";
import ArticleGrid from "../Components/ArticleGrid";
import ArticleVideoGrid from "../Components/ArticleVideoGrid";
import Ads from "../Components/AdsComponent";
import Loadingimage from "../Components/Loadingimage";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer";
import website from '../img/website 2.png';
import vb from '../img/VB-Logo-white.png';
import envelop from '../img/Email-cnt 2.png';
import linkedin from '../img/Linkedin-white.png';
import closeIt from '../img/close-icon-white.png';
import TagManager from 'react-gtm-module';
import { useGlobalContext } from '../context';
import { useAppState } from '../newsletterContext';

const Homepage = () => {
    const tagManagerArgs = {
        gtmId: 'GTM-PJWSS39',
        dataLayerName: 'dataLayer'
    }
    TagManager.initialize(tagManagerArgs);

    //const [loading, setLoading] = useState(false); 
    //const [filterArticles, setDataApis] = useState([]);
    //const [filterVideos, setDataVideos] = useState([]);
    //const [adsData, setDataAds] = useState([]);
    //const [filterAllArticleVideo, setDataAllArticleVideo] = useState([]);
    const [isAgreeChecked, setIsAgreeChecked] = useState(true)
    const [isPrivacyChecked, setIsPrivacyChecked] = useState(true);
    const [emailValidation, setEmailValidation] = useState(false);
    const [responseModal, setResponseModal] = useState(false);
    const [resMessage, setResMessage] = useState('')

    const { loading, filterArticles, filterVideos, adsData, filterAllArticleVideo } = useGlobalContext();
    const { subscribeModal, updateState } = useAppState();

    // const [subscribeModal, setSubscribeModal] = useState(false)
    //console.log(filterArticles);

    /* useEffect(() => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: JSON.stringify({token:'YWxhZGR'})
        };
        Promise.all([
            fetch('https://www.pharmacompass.com/app/other/others/articlesApis', requestOptions),
            fetch('https://www.pharmacompass.com/app/other/others/videosApis', requestOptions),
            fetch('https://www.pharmacompass.com/app/other/others/adsAPI', requestOptions),
            fetch('https://www.pharmacompass.com/app/other/others/allAPIs', requestOptions)
        ]).then(([resArticles, resVideos, resAds, resAll]) => 
            Promise.all([resArticles.json(), resVideos.json(), resAds.json(), resAll.json()])
        ).then(([dataArticles, dataVideos, dataAds, dataAllArticleVideo]) => {
            setDataApis(dataArticles);
            setDataVideos(dataVideos);
            setDataAds(dataAds);
            setDataAllArticleVideo(dataAllArticleVideo);
            setLoading(false);
        } 
        )
    }, []);  */

    const onChangeSearchHandler = (searchValue) => { }

    const closeModal = () => {
        updateState(false)
    }

    const formSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        const email = formData.get('email');
        const agreeNewsletter = formData.get('agree') === null ? 0 : 1;
        const acceptPrivacyPolicy = formData.get('privacyPolicy') === null ? 0 : 1;

        let re = /\S+@\S+\.\S+/;
        let output = re.test(email);

        if (output === true) {
            fetch('https://www.pharmacompass.com/app/other/others/newsletter', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: JSON.stringify({ token: 'YWxhZGR', email: email, agree: agreeNewsletter })
            })
                .then(response => {
                    return response.json();
                })
                .then(
                    data => {
                        if (data.msg === "THIS EMAIL ADDRESS HAVE ALREADY BEEN REGISTERED.") {
                            updateState(false);
                            setResponseModal(true);
                            setResMessage(data.msg)
                        }
                        else {
                            setResMessage(data.msg)
                            updateState(false);
                            setResponseModal(true);
                            setResMessage(data.msg)
                        }
                    })

                .catch((error) => {
                    console.log("error", error);
                });
        }
        else {
            setEmailValidation(true);
        }


    }

    useEffect(() => {
        setEmailValidation(false);
    }, [updateState])


    return (
        <>
            <div className="hmPage">
                <Header onSearchData={onChangeSearchHandler} autoFocus={false} />
                {filterArticles.radioCompassBlog !== undefined ? (
                    <>
                        <section className="main-container" >
                            <ArticleVideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Latest Content" subtitle="By PharmaCompass" category="All Blog" rank="" />
                            <h1 className="titleHeading showdskView"><span className="titleBoxVd">Sponsored Content</span> <span className="subtitle">#CorporateAds</span></h1>
                            <Ads adsData={adsData} />
                            <div className="popUpAd mobileViewLink showMbads">
                                <h1 className="titleHeading"><span className="titleBoxVd">Sponsored Content</span> <span className="subtitle">#CorporateAds</span> </h1>
                                <p className="adsTitleMb">{adsData[0].title}</p>
                                {adsData[0].mobileImg === '' ?
                                    (
                                        <a href={adsData[0].link} target='_blank' rel="noopener noreferrer">{adsData[0].adButler}</a>
                                    )
                                    :
                                    (
                                        <a href={adsData[0].link} target='_blank' rel="noopener noreferrer"><img className="adsImg popAds" src={adsData[0].mobileImg} alt={adsData[0].title} /></a>
                                    )
                                }
                                <div className="mobileViewLink linkhref">
                                    <a className={`${adsData[0].bVirBooth === '' ? "noLinkExists" : ""}`} href={adsData[0].bVirBooth} target='_blank' rel="noopener noreferrer"><img src={vb} alt="VB" /> <span className="txtLnk">Virtual Booth</span></a>
                                    <a className={`singleLine websiteSingle ${adsData[0].link === '' ? "noLinkExists" : ""}`} href={adsData[0].link} target='_blank' rel="noopener noreferrer"><img src={website} alt="Website" /> <span className="txtLnk">Website</span></a>
                                    <a className={`singleLine mailSingle increseIcon ${adsData[0].email === '' ? "noLinkExists" : ""}`} href={`mailto:${adsData[0].email}`} target='_blank' rel="noopener noreferrer"><img src={envelop} alt="Email" /> <span className="txtLnk">Email</span></a>
                                    <a className={`singleLine decreaseIcon lastLink ${adsData[0].linkden === '' ? "noLinkExists" : ""}`} href={adsData[0].linkden} target='_blank' rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /> <span className="txtLnk">LinkedIn</span></a>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <VideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Latest Videos" subtitle="Vlog #PharmaReels" category="ReelPharma" rank="1" />
                            <VideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Our Partners’ Videos" subtitle="Vlog #VirtualBooth" category="" rank="1" />
                            <div className="mobileViewLink showMbads">
                                <div className="adsSectionmobile">
                                    <h1 className="titleHeading"><span className="titleBoxVd">Sponsored Content</span> <span className="subtitle">#CorporateAds</span> </h1>
                                    <p className="adsTitleMb">{adsData[1].title}</p>
                                    <div className="rmads">
                                        {adsData[1].imgSrc === '' ?
                                            (
                                                <a href={adsData[1].link} target='_blank' rel="noopener noreferrer">{adsData[1].adButler}</a>
                                            )
                                            :
                                            (
                                                <a href={adsData[1].link} target='_blank' rel="noopener noreferrer"><img className="adsImg readmoreAds" src={adsData[1].imgSrc} alt={adsData[1].title} /></a>
                                            )
                                        }
                                    </div>
                                    <div className="linkhref linkRm">
                                        <a className={`${adsData[1].bVirBooth === '' ? "noLinkExists" : ""}`} href={adsData[1].bVirBooth} target='_blank' rel="noopener noreferrer"><img src={vb} alt="VB" /> <span>Virtual Booth</span></a>
                                        <a className={`${adsData[1].link === '' ? "noLinkExists" : ""}`} href={adsData[1].link} target='_blank' rel="noopener noreferrer"><img src={website} alt="Website" /> <span>Website</span></a>
                                        <a className={`increseIcon ${adsData[1].email === '' ? "noLinkExists" : ""}`} href={`mailto:${adsData[1].email}`} target='_blank' rel="noopener noreferrer"><img src={envelop} alt="Email" /> <span>Email</span></a>
                                        <a className={`lastLink decreaseIcon ${adsData[1].linkden === '' ? "noLinkExists" : ""}`} href={adsData[1].linkden} target='_blank' rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /><span>LinkedIn</span></a>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <ArticleGrid articles={filterArticles.radioCompassBlog} title="Data Compilation & Company Tracker" subtitle="Blog #PharmaFlow" slug="radio-compass-blog" rank="0" />
                            <ArticleGrid articles={filterArticles.pipelineProspectorBlog} title="Monthly Stock Recap" subtitle="Blog #PipelineProspector" slug="pipeline-prospector-blog" rank="0" />
                            <div className="mobileViewLink secondAdsMore showMbads">
                                <div className="adsSectionmobile">
                                    <h1 className="titleHeading"><span className="titleBoxVd">Sponsored Content</span> <span className="subtitle">#CorporateAds</span> </h1>
                                    <p className="adsTitleMb">{adsData[2].title}</p>
                                    <div className="rmads">
                                        {adsData[2].imgSrc === '' ?
                                            (
                                                <a href={adsData[2].link} target='_blank' rel="noopener noreferrer">{adsData[2].adButler}</a>
                                            )
                                            :
                                            (
                                                <a href={adsData[2].link} target='_blank' rel="noopener noreferrer"><img className="adsImg readmoreAds" src={adsData[2].imgSrc} alt={adsData[2].title} /></a>
                                            )
                                        }
                                    </div>
                                    <div className="linkhref linkRm">
                                        <a className={`${adsData[2].bVirBooth === '' ? "noLinkExists" : ""}`} href={adsData[2].bVirBooth} target='_blank' rel="noopener noreferrer"><img src={vb} alt="VB" /> <span>Virtual Booth</span></a>
                                        <a className={`${adsData[2].link === '' ? "noLinkExists" : ""}`} href={adsData[2].link} target='_blank' rel="noopener noreferrer"><img src={website} alt="Website" /> <span>Website</span></a>
                                        <a className={`increseIcon ${adsData[2].email === '' ? "noLinkExists" : ""}`} href={`mailto:${adsData[2].email}`} target='_blank' rel="noopener noreferrer"><img src={envelop} alt="Email" /> <span>Email</span></a>
                                        <a className={`lastLink decreaseIcon ${adsData[2].linkden === '' ? "noLinkExists" : ""}`} href={adsData[2].linkden} target='_blank' rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /><span>LinkedIn</span></a>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <ArticleGrid articles={filterArticles.speakPharmaBlog} title="Interviews" subtitle="Blog #SpeakPharma" slug="speak-pharma" rank="1" />
                            <VideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Weekly News Recap" subtitle="Blog #Phispers" category="Phispers" rank="0" />
                        </section>

                    </>
                )
                    :
                    (
                        <div className={`loadingHome ${loading}`}>
                            <Loadingimage />
                        </div>
                    )
                }
                <Footer />
            </div>

            {subscribeModal &&
                <div className='modal-bg' >
                    <div className="modal-box">
                        <span className="closeIt" onClick={closeModal}><img src={closeIt} height={16} width={16} /></span>
                        <div className="logo">
                            <img className="orngLogoP" src="https://www.pharmacompass.com/image/pc-blue-orange-logo-star-200x200.png" alt="PHARMACOMPASS" height={60} width={60} />
                            <div className="logoText">
                                <h3>PHARMA<span>COMPASS</span></h3>
                                <p className="logotag">Grow Your Pharma Business Digitally</p>
                            </div>
                        </div>

                        <div className="blue-stripe">
                            <h3 className="modal-title">Newsletter, Sign Up, Stay ahead !</h3>
                        </div>

                        <form onSubmit={formSubmit} noValidate>
                            <input type="email" id="email" name="email" placeholder="Email" required /><br />
                            {emailValidation && <span style={{ color: "#fdb42b", fontFamily: "Helvetica", fontSize: "14px" }}>The input is not a valid email</span>}
                            <div style={{ textAlign: "right", marginTop: 8 }}>
                                <input type="submit" className="submit-button" disabled={!isAgreeChecked} />
                            </div>
                            <label className="d-flex align-items-center ">
                                {/* <input type="checkbox" id="agree" name="agree" className="tandC" checked="" /> <span className="checkmark" ></span> */}
                                <input type="checkbox" id="privacyPolicy" name="privacyPolicy" value={isPrivacyChecked === true ? 1 : 0} checked={isPrivacyChecked} onChange={() => setIsPrivacyChecked(!isPrivacyChecked)} />
                                Yes, I would like to receive the newsletter</label>

                            <b className="alert">Please read our <a className="termPColor text-decoration" target="_blank" href="https://www.pharmacompass.com/privacy-policy">Privacy Policy</a>  carefully</b>
                            <label className="d-flex align-items-center ">
                                <input type="checkbox" id="agree" name="agree" value={isAgreeChecked === true ? 1 : 0} checked={isAgreeChecked} onChange={() => setIsAgreeChecked(!isAgreeChecked)} />
                                I accept the Privacy Policy
                                <br />
                            </label>
                            {!isAgreeChecked && <span style={{ color: "#fdb42b", fontFamily: "Helvetica", fontSize: "14px" }}>You must agree to the privacy policy</span>}
                        </form>
                    </div>
                </div>
            }
            {responseModal &&
                <div className='modal-bg modal-bg2' >
                    <div className="modal-box">
                        <span className="closeIt" onClick={() => setResponseModal(false)}><img src={closeIt} height={16} width={16} /></span>
                        <p>{resMessage}</p>
                    </div>
                </div>
            }
        </>
    );
};

export default Homepage;