import {React, useState} from 'react';
import 'reactjs-popup/dist/index.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import exploreIcon from '../img/arrow-with-circle-right-white.png';
import exploreIconArrow from '../img/arrow_forwardwhite.png';
import MobileButtons from "./MobileButtons";

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 5,
      slidesToSlide: 5
    },
    desktop: {
      breakpoint: { max: 1200, min: 1024 },
      items: 4,
      slidesToSlide: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 590 },
      items: 3,
      slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 590, min: 440 },
        items: 2,
        slidesToSlide: 2
    },
    minimobile: {
      breakpoint: { max: 440, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 30 
    }
  };


const ArticleGrid = (props) => {
    const [width, setWidth] = useState(window.innerWidth)

    window.addEventListener('resize', function(event){
        setWidth(window.innerWidth)
    });
    
    const numRows = props.articles.length;
  
    return (
        <div className={`boxScrollVideo boxArticleScroll ${width >= 1301 ? "fixCarousalArticleLi" : ""}`}>
            <h1 className={`titleHeading ${props.subtitle === '' ? "spacetopsubtitle" : ""}`}><a className="linkTxtCursor" href={`https://www.pharmacompass.com/${props.slug}`} target='_blank' rel="noopener noreferrer"><span className="titleBoxVd">{props.title}</span> <span className="subtitle">{props.subtitle}</span><span className="exploreTxt"> Explore All <img className="exploreIconArrow" src={exploreIconArrow} alt="arrowIcon" /></span></a></h1>
            <div className={`box ${numRows === 1 ? "removeTranslate" : ""}`}>
                <Carousel partialVisible={true} responsive={responsive}>
                    {
                        props.articles.map
                        (
                            article => 
                                (
                                    <div key={article.id}>   
                                        {article.id === '' ? 
                                            (
                                                <>
                                                <div className="exploreAllBtn articleExplore"><a className="linkTxtCursor" href={`https://www.pharmacompass.com/${props.slug}`} target='_blank' rel="noopener noreferrer"><img src={exploreIcon} alt="Explore All" /><br/>Explore All</a></div>
                                                </>
                                            )
                                        : 
                                            (
                                                <>
                                                    <div className="hoverBox">
                                                        <div className="hoverBoxInner">
                                                            {article.image === '' ? 
                                                                (
                                                                    <a className="defaultArticleImg" href={`${article.url}`} target='_blank' rel="noopener noreferrer">
                                                                        <img src={article.image_default} alt={article.News} className="articleimg" />
                                                                    </a>
                                                                )
                                                            :
                                                                (
                                                                    <a href={`${article.url}`} target='_blank' rel="noopener noreferrer">
                                                                        <img src={article.image} alt={article.News} className="articleimg" />
                                                                    </a>
                                                                )
                                                            }                                                            
                                                            <MobileButtons button={article} rank={props.rank} subtitle={props.subtitle}/> 
                                                        
                                                            <div className={`hideMobileView boxInner ${props.rank === '0' ? "" : "companyBox"}`}>
                                                                <div className="textVideo">
                                                                    <span className="showHover">{article.date} <span className="dottedViews">.</span> {article.hits}  Views</span>                                
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                )
                        )
                    }
                </Carousel>
            </div>
        </div>
    );
  };  
  export default ArticleGrid;
  