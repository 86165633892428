import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { decode, encode } from 'js-base64';
import VideoCategory from "../Components/VideoCategory";
import Loadingimage from "../Components/Loadingimage";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer";
import { useAppState } from '../newsletterContext';
import closeIt from './../img/close-icon-white.png'

const Category = () => {
    const [filterVideos, setDataVideos] = useState([]);
    const [filterArticlesPop, setDataApisPop] = useState([]);
    const [filterVideosPop, setDataVideosPop] = useState([]);
    const [filterArticlesVideosPop, setDataArticlesVideosPop] = useState([]);
    const [loading, setLoading] = useState(false);

    const [isAgreeChecked, setIsAgreeChecked] = useState(true)
    const [isPrivacyChecked, setIsPrivacyChecked] = useState(true);
    const [emailValidation, setEmailValidation] = useState(false);
    const [responseModal, setResponseModal] = useState(false);
    const [resMessage, setResMessage] = useState('')
    const { subscribeModal, updateState } = useAppState();

    let category = useParams();

    useEffect(() => {
        setLoading(true);
        const requestOptionsPop = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: JSON.stringify({ token: 'YWxhZGR' })
        };
        Promise.all([
            fetch('https://www.pharmacompass.com/app/other/others/articlesApis', requestOptionsPop),
            fetch('https://www.pharmacompass.com/app/other/others/videosApis', requestOptionsPop),
            fetch('https://www.pharmacompass.com/app/other/others/allAPIs', requestOptionsPop)
        ]).then(([resArticles, resVideos, resArticleVideo]) =>
            Promise.all([resArticles.json(), resVideos.json(), resArticleVideo.json()])
        ).then(([dataArticles, dataVideos, dataArticleVideo]) => {
            setDataApisPop(dataArticles);
            setDataVideosPop(dataVideos);
            setDataArticlesVideosPop(dataArticleVideo);
        }
        )

        let startpage = 0;
        let endpage = 30;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: JSON.stringify({ token: 'YWxhZGR', category: decode(category.catId), startpage: startpage, endpage: endpage })
        };
        fetch('https://www.pharmacompass.com/app/other/others/videosApis', requestOptions).then(
            response => {
                return response.json();
            }
        ).then(
            data => {
                setDataVideos(data);
                setLoading(false);
            }
        )

        //Scroll More Data
        let checkFinish = 0;
        const handleScrollEvent = () => {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 300) {
                if (checkFinish < 1) {
                    setLoading(true);
                    checkFinish = 1;
                    startpage = startpage + 30;
                    endpage = endpage + 30;
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        body: JSON.stringify({ token: 'YWxhZGR', category: decode(category.catId), startpage: startpage, endpage: endpage })
                    };
                    fetch('https://www.pharmacompass.com/app/other/others/videosApis', requestOptions).then(
                        response => {
                            return response.json();
                        }
                    ).then(
                        data => {
                            if (data.length > 0) {
                                setDataVideos((prevData) => [...prevData, ...data]);
                                checkFinish = 0;
                            }
                            setLoading(false);
                        }
                    )
                }
            }
        }
        window.addEventListener('scroll', handleScrollEvent);
        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        }

        // eslint-disable-next-line 
    }, []);
  
    const closeModal = () => {
        updateState(false)
    }

    const formSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        const email = formData.get('email');
        const agreeNewsletter = formData.get('agree') === null ? 0 : 1;
        const acceptPrivacyPolicy = formData.get('privacyPolicy') === null ? 0 : 1;

        let re = /\S+@\S+\.\S+/;
        let output = re.test(email);

        if (output === true) {
            fetch('https://www.pharmacompass.com/app/other/others/newsletter', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: JSON.stringify({ token: 'YWxhZGR', email: email, agree: agreeNewsletter })
            })
                .then(response => {
                    return response.json();
                })
                .then(
                    data => {
                        if (data.msg === "THIS EMAIL ADDRESS HAVE ALREADY BEEN REGISTERED.") {
                            updateState(false);
                            setResponseModal(true);
                            setResMessage(data.msg)
                        }
                        else {
                            setResMessage(data.msg)
                            updateState(false);
                            setResponseModal(true);
                            setResMessage(data.msg)
                        }
                    })

                .catch((error) => {
                    console.log("error", error);
                });
        }
        else {
            setEmailValidation(true);
        }


    }

    useEffect(() => {
        setEmailValidation(false);
    }, [updateState])

    const onChangeSearchHandler = (searchValue) => { }
    return (
        <>
            <div className="CategoryPage">
                <Header onSearchData={onChangeSearchHandler} autoFocus={false} />
                <section className="main-container catContainer" >
                    <div className="innerContainer">
                        <VideoCategory videosarticles={filterArticlesVideosPop} videoscat={filterVideos} articles={filterArticlesPop} videos={filterVideosPop}
                            rank=
                            {
                                category.catId === encode("ReelPharma") ? "1"
                                    : category.catId === encode("Phispers") ? "0"
                                        : category.catId === encode("Partners") ? "1"
                                            : null
                            }
                            title=
                            {
                                category.catId === encode("ReelPharma") ? "Latest #ReelPharma"
                                    : category.catId === encode("Phispers") ? "#Phispers"
                                        : category.catId === encode("Partners") ? "Our Partners' Videos"
                                            : null
                            }
                            subtitle=
                            {
                                category.catId === encode("ReelPharma") ? ""
                                    : category.catId === encode("Phispers") ? "Weekly News Recap"
                                        : category.catId === encode("Partners") ? ""
                                            : null
                            }
                            category=
                            {
                                category.catId === encode("ReelPharma") ? "ReelPharma"
                                    : category.catId === encode("Phispers") ? "Phispers"
                                        : category.catId === encode("Partners") ? ""
                                            : null
                            }
                        />
                        {loading ? <Loadingimage /> : ''}
                    </div>
                </section>
                <Footer />
            </div>

            {subscribeModal &&
                <div className='modal-bg' >
                    <div className="modal-box">
                        <span className="closeIt" onClick={closeModal}><img src={closeIt} height={16} width={16} /></span>
                        <div className="logo">
                            <img className="orngLogoP" src="https://www.pharmacompass.com/image/pc-blue-orange-logo-star-200x200.png" alt="PHARMACOMPASS" height={60} width={60} />
                            <div className="logoText">
                                <h3>PHARMA<span>COMPASS</span></h3>
                                <p className="logotag">Grow Your Pharma Business Digitally</p>
                            </div>
                        </div>

                        <div className="blue-stripe">
                            <h3 className="modal-title">Newsletter, Sign Up, Stay ahead !</h3>
                        </div>

                        <form onSubmit={formSubmit} noValidate>
                            <input type="email" id="email" name="email" placeholder="Email" required /><br />
                            {emailValidation && <span style={{ color: "#fdb42b", fontFamily: "Helvetica", fontSize: "14px" }}>The input is not a valid email</span>}
                            <div style={{ textAlign: "right", marginTop: 8 }}>
                                <input type="submit" className="submit-button" disabled={!isAgreeChecked} />
                            </div>
                            <label className="d-flex align-items-center ">
                                {/* <input type="checkbox" id="agree" name="agree" className="tandC" checked="" /> <span className="checkmark" ></span> */}
                                <input type="checkbox" id="privacyPolicy" name="privacyPolicy" value={isPrivacyChecked === true ? 1 : 0} checked={isPrivacyChecked} onChange={() => setIsPrivacyChecked(!isPrivacyChecked)} />
                                Yes, I would like to receive the newsletter</label>

                            <b className="alert">Please read our <a className="termPColor text-decoration" target="_blank" href="https://www.pharmacompass.com/privacy-policy">Privacy Policy</a>  carefully</b>
                            <label className="d-flex align-items-center ">
                                <input type="checkbox" id="agree" name="agree" value={isAgreeChecked === true ? 1 : 0} checked={isAgreeChecked} onChange={() => setIsAgreeChecked(!isAgreeChecked)} />
                                I accept the Privacy Policy
                                <br />
                            </label>
                            {!isAgreeChecked && <span style={{ color: "#fdb42b", fontFamily: "Helvetica", fontSize: "14px" }}>You must agree to the privacy policy</span>}
                        </form>
                    </div>
                </div>
            }
            {responseModal &&
                <div className='modal-bg modal-bg2' >
                    <div className="modal-box">
                        <span className="closeIt" onClick={() => setResponseModal(false)}><img src={closeIt} height={16} width={16} /></span>
                        <p>{resMessage}</p>
                    </div>
                </div>
            }
        </>
    );
};

export default Category;