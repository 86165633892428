import React, { useContext, useEffect, useReducer, useState } from "react";
import reducer from "./reducer";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {

    const initialState = {
        loading: true,
        filterArticles: [],
        filterVideos: [],
        adsData: [],
        filterAllArticleVideo: [],
        MY_API_TOKEN: 'YWxhZGR',
        // setSubscribeModal: false,
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    // const [subscribeModal,setSubscribeModal]= useState(false);

    
    // const updateState = (newState) => {
    //     setSubscribeModal(newState);
    // };


    useEffect(() => {
        //setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: JSON.stringify({ token: state.MY_API_TOKEN })
        };
        Promise.all([
            fetch('https://www.pharmacompass.com/app/other/others/articlesApis', requestOptions),
            fetch('https://www.pharmacompass.com/app/other/others/videosApis', requestOptions),
            fetch('https://www.pharmacompass.com/app/other/others/adsAPI', requestOptions),
            fetch('https://www.pharmacompass.com/app/other/others/allAPIs', requestOptions)
        ]).then(([resArticles, resVideos, resAds, resAll]) =>
            Promise.all([resArticles.json(), resVideos.json(), resAds.json(), resAll.json()])
        ).then(([dataArticles, dataVideos, dataAds, dataAllArticleVideo]) => {

            //console.log(dataAllArticleVideo);
            dispatch({
                action: "SET_API_DATA", payload: {
                    dataArticles: dataArticles,
                    dataVideos: dataVideos,
                    dataAds: dataAds,
                    dataAllArticleVideo: dataAllArticleVideo,
                }
            })

            //setDataApis(dataArticles);
            //setDataVideos(dataVideos);
            //setDataAds(dataAds);
            //setDataAllArticleVideo(dataAllArticleVideo);
            //setLoading(false);
        }
        )
    }, []);

    const updateVideoViews = (id) => {
        setTimeout(() => {

            const requestOptionsVideo = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: JSON.stringify({ token: state.MY_API_TOKEN, id: id })
            };
            fetch('https://www.pharmacompass.com/app/other/others/updateVideoViews', requestOptionsVideo).then(
                response => {
                    return response.json();
                }
            ).then(
                data => {
                    //console.log(data);
                    if (data.response !== 0) {
                        var filterAllArticleVideo1 = state.filterAllArticleVideo;
                        if (filterAllArticleVideo1 != null) {
                            filterAllArticleVideo1 = filterAllArticleVideo1.map((p) => {
                                //console.log(p.id); 
                                if (p.type === 'video' && p.id === id) {
                                    if (parseFloat(p.pv_views) > 0) {
                                        p.pv_views = data.response;
                                    }
                                    else {
                                        p.pv_views = 1;
                                    }
                                }
                                return p;
                            })
                        }
                        var filterVideos1 = state.filterVideos;
                        if (filterVideos1 != null) {
                            filterVideos1 = filterVideos1.map((p) => {
                                if (parseFloat(p.pv_views) > 0) {
                                    p.pv_views = data.response;
                                }
                                else {
                                    p.pv_views = 1;
                                }
                                return p;
                            })
                        }
                        dispatch({
                            action: "SET_API_DATA_1", payload: {
                                filterAllArticleVideo: filterAllArticleVideo1,
                                filterVideos: filterVideos1,
                            }
                        });
                    }
                }
            ).catch((error) => {
                console.log(error);
            });
        }, 3000);
    }

    return <AppContext.Provider value={{ ...state, updateVideoViews }} >{children}</AppContext.Provider>
    // return <AppContext.Provider value={{ ...state, updateVideoViews, updateState }} >{children}</AppContext.Provider>
}

const useGlobalContext = () => {
    return useContext(AppContext);
}

export { AppContext, AppProvider, useGlobalContext };