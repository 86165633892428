import {React, useEffect} from 'react';
import HoverVideoPlayer from 'react-hover-video-player';
import { encode } from 'js-base64';
import 'reactjs-popup/dist/index.css';
import { Link} from 'react-router-dom';
import MobileButtons from "./MobileButtons";

const VideoCategory = (props) => {

	useEffect(() => {
		setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 500)
	}, []);
    //const onChangeSearchHandler = (searchValue) => {}
    return (    
        <div className="boxScrollVideo">
            {
                props.videoscat.map
                (
                    // eslint-disable-next-line 
                    (video, index) => 
                    {
                        if(index < 1)  return <h1 className="titleHeading"><span className="titleBoxVd">{video.headingTiles}</span> <span className="subtitle">{video.subHeadingTiles}</span></h1> 
                    }
                )
            }
            <div className="box detailPageBox">
                {
                    props.videoscat.map
                    (
                        video => 
                            (      
                                <>
                                    {video.id === '' ? 
                                       ''
                                    : 
                                        (
                                            <>                   
                                                <div className="hoverBox">
                                                    <div className="hoverBoxInner">
                                                        <Link className="linkVideoWatch" to={`/video/${encode(video.id+'||'+props.rank+'||'+props.title+'||'+props.subtitle+'||'+props.category+'||cat')}`}>
                                                            <HoverVideoPlayer 
                                                                className="gridVideo" 
                                                                videoSrc={video.videoSrc} 
                                                                playbackStartDelay={1000}
                                                                pausedOverlay={                                        
                                                                    <img
                                                                        src={video.thumbnail}
                                                                        alt="thumnailVideo"
                                                                        className="thumnailVideo"
                                                                        style={{
                                                                            objectFit: 'cover'                                           
                                                                        }}
                                                                    />
                                                                }
                                                                muted={false}
                                                                unloadVideoOnPaused
                                                            />
                                                        </Link>
                                                        <div className="gridimg"><Link className="linkTxtCursor" to={`/video/${encode(video.id+'||'+props.rank+'||'+props.title+'||'+props.subtitle+'||'+props.category+'||cat')}`}><img className="mobile-thumb" src={video.thumbnail} alt="thumnail-mbile" /></Link></div>
                                                        <MobileButtons button={video} rank={props.rank} />

                                                        <div className={`hideMobileView boxInner ${props.rank === '0' ? "" : "companyBox"}`}>
                                                            <div className="textVideo">
                                                                <span className="showHover">{video.date} <span className="dottedViews">.</span> {video.hits}  Views</span>                                
                                                            </div>
                                                        </div>                       
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                    )
                }
            </div>
        </div>
    );
  };  
  export default VideoCategory;
  