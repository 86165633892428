import { React, useState } from 'react';
import HoverVideoPlayer from 'react-hover-video-player';
import { encode } from 'js-base64';
import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import exploreIconArrow from '../img/arrow_forwardwhite.png';
import MobileButtons from "./MobileButtons";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 5,
        slidesToSlide: 5

    },
    desktop: {
        breakpoint: { max: 1200, min: 1024 },
        items: 4,
        slidesToSlide: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 590 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 590, min: 440 },
        items: 2,
        slidesToSlide: 2
    },
    minimobile: {
        breakpoint: { max: 440, min: 0 },
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 30
    }
};
const VideoGrid = (props) => {
    const filterVideos = props.videos.filter(filter => filter.category === `#${props.category}`);
    const [width, setWidth] = useState(window.innerWidth);

    window.addEventListener('resize', function (event) {
        setWidth(window.innerWidth)
    });
    const numRows = filterVideos.length;
    return (
        <div className={`boxScrollVideo videoGridBx ${width >= 1200 ? "fixCarousalVideoLi" : ""}`}>
            {/* for demo =====> /pharmareels/category/ 
            for live /category/ */}
            <h1 className={`titleHeading ${props.subtitle === '' ? "spacetopsubtitle" : ""}`} ><Link className="linkTxtCursor" to={`/category/${props.category === '' ? encode('Partners') : encode(props.category)}`}><span className="titleBoxVd">{props.title}</span> <span className="subtitle">{props.subtitle}</span><span className="exploreTxt"> Explore All <img className="exploreIconArrow" src={exploreIconArrow} alt="arrowIcon" /></span></Link></h1>
            <div className={`box ${numRows === 1 ? "removeTranslate" : ""}`}>
                <Carousel partialVisible={true} responsive={responsive}>
                    {
                        filterVideos.map
                            (
                                video =>
                                (
                                    <div key={video.id}>
                                        {video.id === '' ?
                                            ''
                                            :
                                            (
                                                <>
                                                    <div className="hoverBox">
                                                        <div className="hoverBoxInner">
                                                            {/* for demo =====> /pharmareels/video/ 
            for live /video/ */}
                                                            <Link className="linkVideoWatch" sourcepath="home" to={`/video/${encode(video.id + '||' + props.rank + '||' + props.title + '||' + props.subtitle + '||' + props.category + '||home')}`}>
                                                                <HoverVideoPlayer
                                                                    className="gridVideo potrait-video"
                                                                    videoSrc={video.videoSrc}
                                                                    playbackStartDelay={1000}
                                                                    pausedOverlay={
                                                                        <img
                                                                            src={video.thumbnail}
                                                                            alt="thumnailVideo"
                                                                            className="thumnailVideo"
                                                                            style={{
                                                                                objectFit: 'cover'
                                                                            }}
                                                                        />
                                                                    }
                                                                    muted={false}
                                                                    unloadVideoOnPaused
                                                                />
                                                            </Link>
                                                            {/* for demo =====> /pharmareels/video/ 
            for live /video/ */}
                                                            <div className="gridimg"><Link className="linkTxtCursor" to={`/video/${encode(video.id + '||' + props.rank + '||' + props.title + '||' + props.subtitle + '||' + props.category + '||home')}`}><img className="mobile-thumb" src={video.thumbnail} alt="thumnail-mbile" /></Link></div>
                                                            <MobileButtons button={video} rank={props.rank} subtitle={props.subtitle} />
                                                            <div className={`hideMobileView boxInner ${props.rank === '0' ? "" : "companyBox"}`}>
                                                                <div className="textVideo">
                                                                    <span className="showHover">{video.date} <span className="dottedViews">.</span> {video.pv_views}  Views</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                )
                            )
                    }
                </Carousel>
            </div>
        </div>
    );
};
export default VideoGrid;
