import {React, useState, useEffect} from 'react';
import { useParams, Link, useNavigate  } from 'react-router-dom';
import VideoGrid from "../Components/VideoGrid";
import ArticleGrid from "../Components/ArticleGrid";
import ArticleVideoGrid from "../Components/ArticleVideoGrid";
import Loadingimage from "../Components/Loadingimage";
import Headerpopup from "../Components/Header/Headerpopup";
import Footer from "../Components/Footer";
import { decode } from 'js-base64';
import shareIcon from '../img/share.png';
import MobileButtons from "../Components/MobileButtons";
import { encode } from 'js-base64';
import closeIcon from '../img/close-icon-white.png';
import { useGlobalContext } from '../context';

const VideoPage = () => {
    let {videoId} = useParams();
    const param_data = decode(videoId).split('||');
    const history = useNavigate();
    const {updateVideoViews} = useGlobalContext();

    const [loading, setLoading] = useState(false);
    const [filterArticles, setDataApis] = useState([]);
    const [filterVideos, setDataVideos] = useState([]);
    const [filterAllArticleVideo, setDataAllArticleVideo] = useState([]);
    const [singleVideo, setSingleVideo] = useState([]);
    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: JSON.stringify({token:'YWxhZGR'})
        };
        Promise.all([
            fetch('https://www.pharmacompass.com/app/other/others/articlesApis', requestOptions),
            fetch('https://www.pharmacompass.com/app/other/others/videosApis', requestOptions),
            fetch('https://www.pharmacompass.com/app/other/others/allAPIs', requestOptions)
        ]).then(([resArticles, resVideos, resAll]) => 
            Promise.all([resArticles.json(), resVideos.json(), resAll.json()])
        ).then(([dataArticles, dataVideos, dataAllArticleVideo]) => {
            setDataApis(dataArticles);
            setDataVideos(dataVideos);
            setDataAllArticleVideo(dataAllArticleVideo);
            setLoading(false);
        } 
        )
        
        const requestOptionsVideo = {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: JSON.stringify({token:'YWxhZGR',videoId: param_data[0]})
        };
        fetch('https://www.pharmacompass.com/app/other/others/videoSingle', requestOptionsVideo).then(
            response => {
                return response.json();
            }
        ).then(
            data => {
                setSingleVideo(data);
                updateVideoViews(param_data[0]);
                window.scrollTo(0, 0);
            } 
        );
        
    }, [history]); 
    const onChangeSearchHandler = (searchValue) => {} 
    //Video Mute/Unmute
    /*const [muted, setMuted] = useState(false);
    const handleToggleMute = () => {
        setMuted(current => !current);
    }*/

    const [copySuccess, setCopySuccess] = useState('');
    const copyToClipBoard = async copyMe => {
        try {
          await navigator.clipboard.writeText(copyMe);
          setCopySuccess('copy');          
        } catch (err) {
          setCopySuccess('Failed to copy!');
        }
        setTimeout(() => {
            setCopySuccess('');
        }, 1000);
    };

    return (  
        <>  
            <div className="desktopVideoPage">
                {param_data[5] === 'cat'?
                    (
                        <h1 className="headingWatchVideo"><span>{singleVideo.title}</span> <Link to={`/category/${encode(param_data[4])}`} className="closePage closepagepop"><img src={closeIcon} alt="close" /></Link></h1>
                    ):
                    (
                        <h1 className="headingWatchVideo"><span>{singleVideo.title}</span> <Link to='/' className="closePage closepagepop"><img src={closeIcon} alt="close" /></Link></h1>
                    )
                }
                <video className="fullScreenVideo" controls width="100%" height="100%" src={singleVideo.videoSrc} key={singleVideo.id} autoPlay={true}  muted={false} />
            </div>
            <div className="popup-content mobileVideoPage">   
                <Headerpopup onSearchData={onChangeSearchHandler} autoFocus={false} />        
                {filterArticles.radioCompassBlog !== undefined ? (
                    <> 
                        <section className="content videoPopup">
                            {param_data[5] === 'cat'?
                                (
                                    <div className="positionrel"><Link to={`/category/${encode(param_data[4])}`}  className="closepagepop">&times;</Link></div>
                                ):
                                (
                                    <div className="positionrel"><Link to={`/`} className="closepagepop">&times;</Link></div>
                                )
                            }
                            
                            <video width="100%" src={singleVideo.videoSrc} controls autoPlay={true}  muted webkit-playsinline="true" playsinline="true" />
                            <div className={`boxInner playBtnBoxArea ${param_data[1] === '0' ? "" : "companyBox"}`}>
                                <div className="textVideoPop">
                                    <div className="cntSectionVideo">
                                        <span className="titleVideo">{singleVideo.title}</span>
                                    </div>
                                    {/* <div className="shareBtnTxt popupShareTxt" onClick={(e) => { e.preventDefault(); copyToClipBoard(`${window.location.href}`)}}>
                                        <img src={shareIcon} alt="Share" /> Share
                                        {copySuccess === 'copy' ? (<span className="copyTxt displayBlck videoPageShr">Copied!</span>) : ''}
                                    </div> */}
                                    <MobileButtons button={singleVideo} rank={param_data[1]} />
                                    <span className="videoDate blowtiletxt">
                                        {param_data[1] === '0' ? 
                                            (
                                                <>
                                                {singleVideo.date} <span className="showHover"><span className="dottedViews">.</span> {singleVideo.pv_views}  Views</span>
                                                </>
                                            )
                                        : 
                                            (
                                                <>                                                                        
                                                    <span className="cmpName">{singleVideo.company}</span>
                                                    <span className="showHover">{singleVideo.date} <span className="dottedViews">.</span> {singleVideo.pv_views}  Views</span>
                                                </>
                                            )
                                        }
                                    </span>
                                    <div className="clearfix"></div>
                                </div>
                            </div>

                            <div className="main-container">
                                {param_data[4] === 'All Blog'?(<ArticleVideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Latest Content" subtitle="By PharmaCompass" category="All Blog" rank=""/> ):(<VideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title={param_data[2]} subtitle={param_data[3]} category={param_data[4]} rank={param_data[1]}/>)}
                
                                {param_data[4] === 'All Blog'?'':(<ArticleVideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Latest Content" subtitle="By PharmaCompass" category="All Blog" rank=""/>)}
                                {param_data[4] === 'ReelPharma'?'':(<VideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Latest Videos" subtitle="Vlog #PharmaReels" category="ReelPharma" rank="1" />)}
                                {param_data[4] === ''?'':(<VideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Our Partners’ Videos" subtitle="Vlog #VirtualBooth" category="" rank="1"/>)} 
                                <ArticleGrid articles={filterArticles.radioCompassBlog} title="Data Compilation & Company Tracker" subtitle="Blog #PharmaFlow" slug="radio-compass-blog" rank="0" />
                                <ArticleGrid articles={filterArticles.pipelineProspectorBlog} title="Monthly Stock Recap" subtitle="Blog #PipelineProspector" slug="pipeline-prospector-blog" rank="0" />
                                <ArticleGrid articles={filterArticles.speakPharmaBlog} title="Interviews" subtitle="Blog #SpeakPharma" slug="speak-pharma" rank="1"/> 
                                {param_data[4] === 'Phispers'?'':(<VideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Blog Weekly News Recap" subtitle="#Phispers" category="Phispers" rank="0"/>)}
                            </div>                 
                        </section>
                        
                    </>
                    )
                    :
                    (
                        <div className={`loadingHome ${loading}`}>
                            <Loadingimage />
                        </div>
                    )
                }
                <Footer />
            </div>
        </>
    );
};

export default VideoPage;