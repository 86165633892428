import { React, useState } from 'react';
import VideoGrid from "../Components/VideoGrid";
import ArticleGrid from "../Components/ArticleGrid";
//import Loadingimage from "../Components/Loadingimage";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer";
//import ArticleVideoGrid from "../Components/ArticleVideoGrid";
import { useGlobalContext } from '../context';
import ArticleVideoGrid from '../Components/ArticleVideoGrid';
import AdsComponent from '../Components/AdsComponent';
import website from '../img/website 2.png';
import Loadingimage from "../Components/Loadingimage";

import vb from '../img/VB-Logo-white.png';
import envelop from '../img/Email-cnt 2.png';
import linkedin from '../img/Linkedin-white.png';
import TagManager from 'react-gtm-module';


const Search = () => {
    //const [loading, setLoading] = useState(false);
    const { loading, filterArticles, filterVideos, adsData, filterAllArticleVideo } = useGlobalContext();
    const [filteredArticles, setDataApis] = useState([]);
    const [filteredVideos, setDataVideos] = useState([]);
    const [addClass, setAddClass] = useState('d-n');

    //const [filterAllArticleVideo, setDataAllArticleVideo] = useState([]);

    const onChangeSearchHandler = (searchValue) => {
        fetchSearchRecords(searchValue);
    }
    /*useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const searchData = params.get('q');
        fetchSearchRecords(searchData);        
    }, []);*/

    const fetchSearchRecords = (search) => {
        //setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: JSON.stringify({ token: 'YWxhZGR', searchData: search })
        };
        Promise.all([
            fetch('https://www.pharmacompass.com/app/other/others/articlesApis', requestOptions),
            fetch('https://www.pharmacompass.com/app/other/others/videosApis', requestOptions),
            //fetch('https://www.pharmacompass.com/app/other/others/allAPIs', requestOptions)
        ]).then(([resArticles, resVideos, resAll]) =>
            Promise.all([resArticles.json(), resVideos.json()])

        ).then(([dataArticles, dataVideos]) => {
            setDataApis(dataArticles);
            setDataVideos(dataVideos);
            setAddClass('d-b');
            //setDataAllArticleVideo(dataAllArticleVideo);
            //setLoading(false);
        })
    }



    return (
        <div className="searchPage">
            <Header onSearchData={onChangeSearchHandler} autoFocus={true} />
            <section className="main-container" >
                {/*filterAllArticleVideo !== undefined ? 
                    (<ArticleVideoGrid filerAllData={filterAllArticleVideo} title="By PharmaCompass" subtitle="Latest Content" category="All Blog" rank=""/>)
                : ''*/}

                {filteredVideos.length === 0 && (filteredArticles.length === 0 || filteredArticles === undefined) ?
                    <>
                        {filterArticles.radioCompassBlog !== undefined ? (
                            <>
                                <div className={addClass}>
                                    <h3 className='result-title'>Unfortunately, we couldn't find any results for your query.</h3>
                                    <p className='result-para'>
                                        Please enter a different keyword, browse the latest content by PharmaComapass or <a href='https://www.pharmacompass.com/share-a-request' target='_blank'>ASK US!</a>
                                    </p>
                                <section className="" >
                                    <ArticleVideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Latest Content" subtitle="By PharmaCompass" category="All Blog" rank="" />
                                    <h1 className="titleHeading showdskView"><span className="titleBoxVd">Sponsored Content</span> <span className="subtitle">#CorporateAds</span></h1>
                                    <AdsComponent adsData={adsData} />
                                    <div className="popUpAd mobileViewLink showMbads">
                                        <h1 className="titleHeading"><span className="titleBoxVd">Sponsored Content</span> <span className="subtitle">#CorporateAds</span> </h1>
                                        <p className="adsTitleMb">{adsData[0].title}</p>
                                        {adsData[0].mobileImg === '' ?
                                            (
                                                <a href={adsData[0].link} target='_blank' rel="noopener noreferrer">{adsData[0].adButler}</a>
                                            )
                                            :
                                            (
                                                <a href={adsData[0].link} target='_blank' rel="noopener noreferrer"><img className="adsImg popAds" src={adsData[0].mobileImg} alt={adsData[0].title} /></a>
                                            )
                                        }
                                        <div className="mobileViewLink linkhref">
                                            <a className={`${adsData[0].bVirBooth === '' ? "noLinkExists" : ""}`} href={adsData[0].bVirBooth} target='_blank' rel="noopener noreferrer"><img src={vb} alt="VB" /> <span className="txtLnk">Virtual Booth</span></a>
                                            <a className={`singleLine websiteSingle ${adsData[0].link === '' ? "noLinkExists" : ""}`} href={adsData[0].link} target='_blank' rel="noopener noreferrer"><img src={website} alt="Website" /> <span className="txtLnk">Website</span></a>
                                            <a className={`singleLine mailSingle increseIcon ${adsData[0].email === '' ? "noLinkExists" : ""}`} href={`mailto:${adsData[0].email}`} target='_blank' rel="noopener noreferrer"><img src={envelop} alt="Email" /> <span className="txtLnk">Email</span></a>
                                            <a className={`singleLine decreaseIcon lastLink ${adsData[0].linkden === '' ? "noLinkExists" : ""}`} href={adsData[0].linkden} target='_blank' rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /> <span className="txtLnk">LinkedIn</span></a>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                    <VideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Latest Videos" subtitle="Vlog #PharmaReels" category="ReelPharma" rank="1" />
                                    <VideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Our Partners’ Videos" subtitle="Vlog #VirtualBooth" category="" rank="1" />
                                    <div className="mobileViewLink showMbads">
                                        <div className="adsSectionmobile">
                                            <h1 className="titleHeading"><span className="titleBoxVd">Sponsored Content</span> <span className="subtitle">#CorporateAds</span> </h1>
                                            <p className="adsTitleMb">{adsData[1].title}</p>
                                            <div className="rmads">
                                                {adsData[1].imgSrc === '' ?
                                                    (
                                                        <a href={adsData[1].link} target='_blank' rel="noopener noreferrer">{adsData[1].adButler}</a>
                                                    )
                                                    :
                                                    (
                                                        <a href={adsData[1].link} target='_blank' rel="noopener noreferrer"><img className="adsImg readmoreAds" src={adsData[1].imgSrc} alt={adsData[1].title} /></a>
                                                    )
                                                }
                                            </div>
                                            <div className="linkhref linkRm">
                                                <a className={`${adsData[1].bVirBooth === '' ? "noLinkExists" : ""}`} href={adsData[1].bVirBooth} target='_blank' rel="noopener noreferrer"><img src={vb} alt="VB" /> <span>Virtual Booth</span></a>
                                                <a className={`${adsData[1].link === '' ? "noLinkExists" : ""}`} href={adsData[1].link} target='_blank' rel="noopener noreferrer"><img src={website} alt="Website" /> <span>Website</span></a>
                                                <a className={`increseIcon ${adsData[1].email === '' ? "noLinkExists" : ""}`} href={`mailto:${adsData[1].email}`} target='_blank' rel="noopener noreferrer"><img src={envelop} alt="Email" /> <span>Email</span></a>
                                                <a className={`lastLink decreaseIcon ${adsData[1].linkden === '' ? "noLinkExists" : ""}`} href={adsData[1].linkden} target='_blank' rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /><span>LinkedIn</span></a>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                    <ArticleGrid articles={filterArticles.radioCompassBlog} title="Data Compilation & Company Tracker" subtitle="Blog #PharmaFlow" slug="radio-compass-blog" rank="0" />
                                    <ArticleGrid articles={filterArticles.pipelineProspectorBlog} title="Monthly Stock Recap" subtitle="Blog #PipelineProspector" slug="pipeline-prospector-blog" rank="0" />
                                    <div className="mobileViewLink secondAdsMore showMbads">
                                        <div className="adsSectionmobile">
                                            <h1 className="titleHeading"><span className="titleBoxVd">Sponsored Content</span> <span className="subtitle">#CorporateAds</span> </h1>
                                            <p className="adsTitleMb">{adsData[2].title}</p>
                                            <div className="rmads">
                                                {adsData[2].imgSrc === '' ?
                                                    (
                                                        <a href={adsData[2].link} target='_blank' rel="noopener noreferrer">{adsData[2].adButler}</a>
                                                    )
                                                    :
                                                    (
                                                        <a href={adsData[2].link} target='_blank' rel="noopener noreferrer"><img className="adsImg readmoreAds" src={adsData[2].imgSrc} alt={adsData[2].title} /></a>
                                                    )
                                                }
                                            </div>
                                            <div className="linkhref linkRm">
                                                <a className={`${adsData[2].bVirBooth === '' ? "noLinkExists" : ""}`} href={adsData[2].bVirBooth} target='_blank' rel="noopener noreferrer"><img src={vb} alt="VB" /> <span>Virtual Booth</span></a>
                                                <a className={`${adsData[2].link === '' ? "noLinkExists" : ""}`} href={adsData[2].link} target='_blank' rel="noopener noreferrer"><img src={website} alt="Website" /> <span>Website</span></a>
                                                <a className={`increseIcon ${adsData[2].email === '' ? "noLinkExists" : ""}`} href={`mailto:${adsData[2].email}`} target='_blank' rel="noopener noreferrer"><img src={envelop} alt="Email" /> <span>Email</span></a>
                                                <a className={`lastLink decreaseIcon ${adsData[2].linkden === '' ? "noLinkExists" : ""}`} href={adsData[2].linkden} target='_blank' rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /><span>LinkedIn</span></a>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                    <ArticleGrid articles={filterArticles.speakPharmaBlog} title="Interviews" subtitle="Blog #SpeakPharma" slug="speak-pharma" rank="1" />
                                    <VideoGrid filerAllData={filterAllArticleVideo} videos={filterVideos} articles={filterArticles} title="Weekly News Recap" subtitle="Blog #Phispers" category="Phispers" rank="0" />
                                </section>
                                </div>


                            </>
                        )
                            :
                            (
                                <div className={`loadingHome ${loading}`}>
                                    <Loadingimage />
                                </div>
                            )
                        }
                    </> : ''
                }

                {filteredVideos.filter(filter => filter.category === `#ReelPharma`).length > 0
                    ?
                    (<VideoGrid videos={filteredVideos} title="Latest Videos" subtitle="Vlog #PharmaReels" category="ReelPharma" rank="1" />)
                    : ''
                }
                {filteredVideos.filter(filter => filter.category === `#`).length > 0
                    ?
                    (<VideoGrid videos={filteredVideos} title="Our Partners’ Videos" subtitle="Vlog #VirtualBooth" category="" rank="1" />)
                    : ''
                }
                {filteredArticles.radioCompassBlog !== undefined
                    ?
                    (<ArticleGrid articles={filteredArticles.radioCompassBlog} title="Data Compilation & Company Tracker" subtitle="Blog #PharmaFlow" slug="radio-compass-blog" rank="0" />)
                    : ''
                }
                {filteredArticles.pipelineProspectorBlog !== undefined
                    ?
                    (<ArticleGrid articles={filteredArticles.pipelineProspectorBlog} title="Monthly Stock Recap" subtitle="Blog #PipelineProspector" slug="pipeline-prospector-blog" rank="0" />)
                    : ''
                }
                {filteredArticles.speakPharmaBlog !== undefined
                    ?
                    (<ArticleGrid articles={filteredArticles.speakPharmaBlog} title="Interviews" subtitle="Blog #SpeakPharma" slug="speak-pharma" rank="1" />)
                    : ''
                }

                {filteredVideos.filter(filter => filter.category === `#Phispers`).length > 0
                    ?
                    (<VideoGrid videos={filteredVideos} title="Weekly News Recap" subtitle="Blog #Phispers" category="Phispers" rank="0" />)
                    : ''
                }
            </section>
            <Footer />
        </div>
    );
};

export default Search;