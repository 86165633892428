import { React, useState, useEffect } from 'react';
import './Header.css';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import logo from './pc-logo-star-orange-gradient-blue-bg-final-100x100.png';
import searchicon from './searchicon.png';
import lnkIcon from '../../img/linkedin-icon-1blue-100x100.png';
import backmicon from './back-to-main-website-button-mobile.png';
import menuOpen from '../../img/menu-dropdown-white.png';

const Header = (props) => {
  let navigate = useNavigate();
  //const [checknavigate, setNavigate] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setQuery] = useState(searchParams.get('q'));
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const handleKeyDown = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    /*setSearchParams({
      q: newQuery
    });*/

    //if(checknavigate === 0) { navigate('/search?q='+newQuery); setNavigate(1);}
    if (event.target.value === '') navigate('/');

    //props.onSearchData(newQuery);
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue !== null && searchValue !== '') {
        //console.log(searchValue)
        navigate('/search?q=' + searchValue);
        props.onSearchData(searchValue);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const [scroll, setScroll] = useState(false);
  const [getFlap, setDataFlap] = useState([]);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: JSON.stringify({ token: 'YWxhZGR' })
    };
    Promise.all([
      fetch('https://www.pharmacompass.com/app/other/others/flapAds', requestOptions)
    ]).then(([resFlap]) =>
      Promise.all([resFlap.json()])
    ).then(([dataFlap]) => {
      setDataFlap(dataFlap);
    }
    )
  }, []);

  return (
    <div>
      {getFlap[0] !== undefined ? (
        <div className="flapAds">
          <div className="leftBand"><p className="firstP"></p><p className="secondP"></p><p className="thirdP"></p></div>
          <div className="flapAdsInner">
            <a href={getFlap[0].link} target='_blank' rel="noopener noreferrer">
              <img className="serviceAdsDesktop" src={getFlap[0].imgSrcDesk} alt={getFlap[0].title} />
              <img className="serviceAdsMobile" src={getFlap[0].imgSrcMob} alt={getFlap[0].title} />
            </a>
          </div>
          <div className="rightBand"><p className="firstP"></p><p className="secondP"></p><p className="thirdP"></p></div>
        </div>) : ''}
      <header className={scroll ? "fixHeader" : ""}>
        <div className="headerArea">
          <div className="desktopHeader">
            <div className="searchInputDiv"><span><img src={searchicon} alt="searchicon" /></span><input autoFocus={props.autoFocus} className="searchInput" type="text" onChange={handleKeyDown} value={`${searchValue ? searchValue : ''}`} /></div>
            <div className="backBtnWebsite">
              {/* <a href='https://www.pharmacompass.com/' target='_blank' rel="noopener noreferrer">
                <img src={backicon} alt="Back" />
              </a> */}
              <div className='va-center'>
                <a href='https://www.pharmacompass.com/share-a-request' target='_blank'><button className='ask-us'>ASK US!</button></a>
                <a href='https://in.linkedin.com/company/pharma-compass' target='_blank' className='ms-22'><button className='clear-button va-center '>Follow Us <img className="lnkImg ms-6" src={lnkIcon} alt="Linkedin" /></button></a>
              </div>
            </div>


            <div className="netflixLogo">
              <Link id="logo" to="/">
              {/* <Link id="logo" to="/pharmareels"> */}
                <img src={logo} alt="Logo" />
                <div className="logo-text">
                  <span>PHARMACOMPASS</span>
                  <h1 className="logotag">Grow Your Pharma Business Digitally</h1>
                </div>
              </Link>
            </div>
          </div>

          {/* for mobile */}
          <div className="mobileHeader">
            <div className="upperLogoHead">
              <div className="netflixLogo">
                <Link id="logo" to="/">
                  <img src={logo} alt="Logo" />
                  <div className="logo-text">
                    <span>PHARMACOMPASS</span>
                    <h1 className="logotag">Grow Your Pharma Business Digitally</h1>
                  </div>
                </Link>
              </div>
              <div className="backBtnWebsite text-end">
                {/* <a href='https://www.pharmacompass.com/' target='_blank' rel="noopener noreferrer"><img src={backmicon} alt="Back" /></a> */}
                <button className="clean-btn" onClick={() => setOpenMobileMenu(true)}><img src={menuOpen} alt='open menu' className='menu-hamburger' /></button>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="searchInputDiv"><span><img src={searchicon} alt="searchicon" /></span><input autoFocus={props.autoFocus} className="searchInput" type="text" onChange={handleKeyDown} value={`${searchValue ? searchValue : ''}`} /></div>
          </div>
        </div>
      </header>
      {openMobileMenu === true && <div className='overlay-mobile' onClick={() => setOpenMobileMenu(false)}></div>
      }
      <div className={`mobile-menu ${openMobileMenu ? 'active' : 'non-active'}`}>
        <div className='logoMenuSlick w-100'>
          <div className="mobile-logo">
            <div className="netflixLogo mobile">
              <Link id="logo" to="/">
                <img src={logo} alt="Logo" />
                <div className="logo-text">
                  <span> PHARMA</span><span>COMPASS</span>
                  <h1 className="logotag">Grow Your Pharma Business Digitally</h1>
                </div>
              </Link>
              <div className="close-menu" onClick={() => setOpenMobileMenu(false)}>X</div>
            </div>
          </div>
        </div>
        <div>
        <ul>
            <li><a href='/category/UmVlbFBoYXJtYQ==' >LATEST VIDEOS</a></li>
            <li><a href='/category/UGFydG5lcnM=' >OUR PARTNERS' VIDEOS</a></li>
            <li><a href='https://www.pharmacompass.com/radio-compass-blog' target='_blank' >DATA COMPILATIONS & COMPANY TRACKER</a></li>
            <li><a href='https://www.pharmacompass.com/pipeline-prospector-blog' target='_blank' >MONTHLY STOCK RECAP</a></li>
            <li><a href='https://www.pharmacompass.com/speak-pharma' target='_blank'>INTERVIEWS</a></li>
            <li><a href='/category/UGhpc3BlcnM='>WEEEKLY NEWS RECAP</a></li>
            <li><a href='https://in.linkedin.com/company/pharma-compass' target='_blank' >FOLLOW US <img src={lnkIcon} className='lnkImg' alt='' /></a></li>
            <li><a href='https://www.pharmacompass.com/share-a-request' target='_blank' >ASK US!</a></li>
          </ul>
        </div>
      </div>



      <div className={scroll ? "spaceTopDiv" : ""}></div>
    </div>
  );
};

export default Header;
