import { React, useState } from 'react';
import website from '../img/website 2.png';
import vb from '../img/VB-Logo-white.png';
import envelop from '../img/Email-cnt 2.png';
import linkedin from '../img/Linkedin-white.png';
import articleIcon from '../img/Read-article-white.png';
import shareIcon from '../img/share.png';
import excelIcon from '../img/excel.png';
import { encode } from 'js-base64';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../context';
import { useAppState } from '../newsletterContext';

const MobileButtons = (props) => {
    const [copySuccess, setCopySuccess] = useState('');
    // const [subscribeModal, setSubscribeModal] = useState(true);

    // const { subscribeModal, useAppState } = useGlobalContext();
    const { subscribeModal, updateState } = useAppState();

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess('copy');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
        setTimeout(() => {
            setCopySuccess('');
        }, 1000);
    };
    const handleClick = () => {
        updateState(true)
    };

    return (
        <div className="btnsMobile">
            {props.rank === '0' ?
                ((props.subtitle === "By PharmaCompass" && props.button.category === "#Phispers") || props.button.category === "#Phispers" ? <>
                    <div className="mobileViewLink btnsShow ">
                        <a className="linkTxtCursor first-link" href={`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`} target='_blank'> <span className="txtLnk pstenQuiry">More News</span></a>
                        {
                            props.button.videoSrc !== undefined && props.button.videoSrc != '' ? (
                                <a className="singleLine position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`https://www.pharmareels.com/video/${encode(props.button.id + '||' + props.button.rank + '||' + props.button.title + '||' + props.button.subtitle + '||' + props.button.category + '||home')}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                            ) : (
                                <a className="singleLine position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                            )
                        }
                        <a className="singleLine lastLink" href='#' onClick={(e)=>e.preventDefault()}><button className="lastLink link-anchor"  onClick={handleClick}> <span className="txtLnk pstenQuiry" style={{color:"#fff"}}>Subscribe</span></button></a>
                    </div></> : (props.subtitle === "By PharmaCompass" && props.button.category === "#speakPharma")?
                    <>
                    <div className="mobileViewLink btnsShow ">
                    <a className={`increseIcon singleLine first-link emailIcons ${props.button.email === '' ? "noLinkExists" : ""}`} href={`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`} target='_blank' rel="noopener noreferrer"><span className="txtLnk">Read </span></a>
                    {
                            props.button.videoSrc !== undefined && props.button.videoSrc != '' ? (
                                <a className="singleLine position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`https://www.pharmareels.com/video/${encode(props.button.id + '||' + props.button.rank + '||' + props.button.title + '||' + props.button.subtitle + '||' + props.button.category + '||home')}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                            ) : (
                                <a className="singleLine position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                            )
                        }
                                <a className={`vbBothBtn  lastLink`} href={`${props.button.vblink}`} target='_blank' rel="noopener noreferrer"><span className="txtLnk">Virtual Booth</span></a></div> </>: 
                    props.subtitle !== "Blog #Phispers" ?
                        <>
                            <div className="mobileViewLink btnsShow ">
                                <a className={`increseIcon first-link ${props.button.phisperurl === '' && props.button.url === '' ? "noLinkExists" : ""}`} href={`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`} target='_blank' rel="noopener noreferrer"><span className="txtLnk">Read Article</span></a>
                                {props.button.videoSrc !== undefined && props.button.videoSrc != '' ? (
                                    <a className="singleLine position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`https://www.pharmareels.com/video/${encode(props.button.id + '||' + props.button.rank + '||' + props.button.title + '||' + props.button.subtitle + '||' + props.button.category + '||home')}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                                ) : (
                                    <a className="singleLine position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                                )
                                }
                                <a className={`increseIcon lastLink ${props.button.email === '' ? "noLinkExists" : ""}`} href={`${props.button.dataCompilation ? props.button.dataCompilation : props.button.url}`} target='_blank' rel="noopener noreferrer"><img src={excelIcon} alt="Email" /> <span className="txtLnk pstenQuiry">Request</span></a>
                                {/* <a className="decreaseIcon singleLine followUsIcon" href="https://in.linkedin.com/company/pharma-compass" target='_blank' rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /> <span className="txtLnk lnkKedn">Follow Us</span></a>
                        <a className={`increseIcon lastLink ${props.button.email === '' ? "noLinkExists" : ""}`} href={`mailto:${props.button.email}`} target='_blank' rel="noopener noreferrer"><img src={envelop} alt="Email" /> <span className="txtLnk pstenQuiry">Post Enquiry</span></a> */}
                                {/* <div className="clearfix"></div> */}
                            </div>
                        </> :
                        <>
                            <div className="mobileViewLink btnsShow ">
                                <a className="linkTxtCursor first-link" href={`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`} target='_blank'> <span className="txtLnk pstenQuiry">More News</span></a>
                                {/* <a className={`increseIcon first-link ${props.button.email === '' ? "noLinkExists" : ""}`} href="#" target='_blank' rel="noopener noreferrer"> <span className="txtLnk pstenQuiry">More News</span></a> */}
                                {
                                    props.button.videoSrc !== undefined && props.button.videoSrc != '' ? (
                                        <a className="singleLine position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`https://www.pharmareels.com/video/${encode(props.button.id + '||' + props.button.rank + '||' + props.button.title + '||' + props.button.subtitle + '||' + props.button.category + '||home')}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                                    ) : (
                                        <a className="singleLine position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                                    )
                                }
                                <button className="anchor-button lastLink"  onClick={handleClick}> <span className="txtLnk pstenQuiry">Subscribe</span></button>
                                {/* <a type='button' href="#" className={`increseIcon lastLink ${props.button.email === '' ? "noLinkExists" : ""}`} onClick={(e) => {handleClick() }} target='_blank' rel="noopener noreferrer"> <span className="txtLnk pstenQuiry">Subscribe</span></a> */}
                            </div>
                        </>
                ) :
                (
                    props.subtitle === "By PharmaCompass" && props.button.category === "#Phispers" ? <>
                        <div className="mobileViewLink btnsShow ">
                            <a className="linkTxtCursor first-link" href={`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`} target='_blank'> <span className="txtLnk pstenQuiry">More News</span></a>
                            {
                                props.button.videoSrc !== undefined && props.button.videoSrc != '' ? (
                                    <a className="singleLine position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`https://www.pharmareels.com/video/${encode(props.button.id + '||' + props.button.rank + '||' + props.button.title + '||' + props.button.subtitle + '||' + props.button.category + '||home')}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                                ) : (
                                    <a className="singleLine position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                                )
                            }
                            <button className="anchor-button lastLink"  onClick={handleClick}> <span className="txtLnk pstenQuiry">Subscribe</span></button>
                        </div></> : (props.subtitle === "By PharmaCompass" && props.button.category === "#speakPharma")?
                    <>
                    <div className="mobileViewLink btnsShow ">
                    <a className={`increseIcon singleLine first-link emailIcons ${props.button.email === '' ? "noLinkExists" : ""}`} href={`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`} target='_blank' rel="noopener noreferrer"><span className="txtLnk">Read </span></a>
                    {
                                props.button.videoSrc !== undefined && props.button.videoSrc != '' ? (
                                    <a className="singleLine position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`https://www.pharmareels.com/video/${encode(props.button.id + '||' + props.button.rank + '||' + props.button.title + '||' + props.button.subtitle + '||' + props.button.category + '||home')}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                                ) : (
                                    <a className="singleLine position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                                )
                            }
                                <a className={`vbBothBtn  lastLink  ${props.button.vblink === '' ? "noLinkExists" : ""}`} href={`${props.button.vblink}`} target='_blank' rel="noopener noreferrer"><span className="txtLnk">Virtual Booth</span></a></div> </>: 
                        <div className={`mobileViewLink btnsShow   ${props.subtitle === "Blog #SpeakPharma" ? 'row-reverse' : ''}`}>
                            <a className={`vbBothBtn  ${props.subtitle === "Blog #SpeakPharma" ?'lastLink ':'first-link'}  ${props.button.vblink === '' ? "noLinkExists" : ""}`} href={`${props.button.vblink}`} target='_blank' rel="noopener noreferrer"><span className="txtLnk">Virtual Booth</span></a>
                            {props.button.videoSrc !== undefined && props.button.videoSrc != '' ? (
                                <a className="singleLine   position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`https://www.pharmareels.com/video/${encode(props.button.id + '||' + props.button.rank + '||' + props.button.title + '||' + props.button.subtitle + '||' + props.button.category + '||home')}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                            ) : (
                                <a className="singleLine  position-rel" onClick={(e) => { e.preventDefault(); copyToClipBoard(`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`) }} href="#" rel="noopener noreferrer"><img src={shareIcon} alt="Share" /> <span className="txtLnk">Share</span>{copySuccess === 'copy' ? (<span className="copyTxt displayBlck slightly-below">Copied!</span>) : ''}</a>
                            )
                            }
                            {props.subtitle === 'Blog #SpeakPharma' ?
                                <>
                                    <a className={`increseIcon singleLine first-link emailIcons ${props.button.email === '' ? "noLinkExists" : ""}`} href={`${props.button.phisperurl ? props.button.phisperurl : props.button.url}`} target='_blank' rel="noopener noreferrer">
                                        <span className="txtLnk">Read </span></a>
                                </> :
                                <>
                                    <a
                                        className={`increseIcon singleLine lastLink  emailIcons ${props.button.email === '' ? "noLinkExists" : ""}`} href={`mailto:${props.button.email}`} target='_blank' rel="noopener noreferrer">
                                        <span className="txtLnk">Email</span>
                                    </a>

                                </>}




                            {/* <a className={`singleLine ${props.button.website === '' ? "noLinkExists" : ""}`} href={`${props.button.website}`} target='_blank' rel="noopener noreferrer"><img src={website} alt="Website" className="websiteMg" /> <span className="txtLnk">Website</span></a>
                        <a className={`singleLine lastLink decreaseIcon ${props.button.linkden === '' ? "noLinkExists" : ""}`} href={`${props.button.linkden}`} target='_blank' rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /> <span className="txtLnk lnkKedn">LinkedIn</span></a> */}
                            {/* <div className="clearfix"></div> */}
                        </div>
                )
            }
        </div>
    )
}

export default MobileButtons;