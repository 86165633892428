import React from "react";

const Loadingimage = (props) => {
    return (        
        <>
            <ul className="shadowLoading">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
            </ul>
        </>
    )
}

export default Loadingimage;