import {React, useEffect, useState} from 'react';
import { useParams, Link } from 'react-router-dom';
import closeIcon from '../img/close-icon-white.png';
import { decode, encode } from 'js-base64';
import { useGlobalContext } from '../context';

const WatchVideo = () => {
	let {page, videoId } = useParams();
	const [watchVideo, setDataVideos] = useState([]);
	const {updateVideoViews} = useGlobalContext();
	
	useEffect(() => {
		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: JSON.stringify({token:'YWxhZGR',videoId: decode(videoId)})
		};
		fetch('https://www.pharmacompass.com/app/other/others/watchVideoAPI', requestOptions).then(
			response => {
				return response.json();
			}
		).then(
			data => {
				setDataVideos(data);
				updateVideoViews(decode(videoId));          
			}
		)
	}, [videoId]);
	
    return (    
		watchVideo.map(
			video => (
				<>
					{page === 'category'?
						(
							<h1 className="headingWatchVideo"><span>{video.title}</span> <Link to={`/category/${encode(video.category)}`} className="closePage"><img src={closeIcon} alt="close" /></Link></h1>
						):
						(
							<h1 className="headingWatchVideo"><span>{video.title}</span> <Link to='/' className="closePage"><img src={closeIcon} alt="close" /></Link></h1>
						)
					}
					<video className="fullScreenVideo" controls width="100%" height="100%" src={video.videoSrc} key={video.id} autoPlay={true}  muted={false} />
				</>
			)
		)
    );
};

export default WatchVideo;