import {React, useState} from 'react';
import HoverVideoPlayer from 'react-hover-video-player';
import { encode } from 'js-base64';
import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import MobileButtons from "./MobileButtons";
//import { useGlobalContext } from '../context';


const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 5,
      slidesToSlide: 5
    },
    desktop: {
      breakpoint: { max: 1200, min: 1024 },
      items: 4,
      slidesToSlide: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 590 },
      items: 3,
      slidesToSlide: 3,
    },
    mobile: {
        breakpoint: { max: 590, min: 440 },
        items: 2,
        slidesToSlide: 2
    },
    minimobile: {
      breakpoint: { max: 440, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 30 
    }
  };

//const onChangeSearchHandler = (searchValue) => {}
const ArticleVideoGrid = (props) => {

    //console.log('ArticleVideoGrid1'); 
    //console.log(props.filerAllData);
    //console.log('ArticleVideoGrid2'); 
   
    //const {updateVideoViews} = useGlobalContext();

    const [width, setWidth] = useState(window.innerWidth);
    window.addEventListener('resize', function(event){
        setWidth(window.innerWidth)
    });
 

    return ( 
        <div className={`boxScrollVideo videoGridBx ${width >= 1200 ? "fixCarousalVideoLi" : ""}`}>
            <h1 className="titleHeading"><span className="titleBoxVd">{props.title}</span> <span className="subtitle">{props.subtitle}</span></h1>
            <div className="box">
                <Carousel partialVisible={true} responsive={responsive}>
                {  
                    props.filerAllData.map
                    (
                        video => 
                        (
                            <>
                                {video.type === 'video' ? 
                                    <div className="hoverBox">
                                        <div className="hoverBoxInner">
                                            <Link className="linkVideoWatch" sourcepath="home" to={`/video/${encode(video.id+'||'+video.party_rank+'||'+props.title+'||'+props.subtitle+'||'+props.category+'||home')}`}>
                                                <HoverVideoPlayer 
                                                    //onMouseOver={() => updateVideoViews(video.id)}
                                                    className="gridVideo potrait-video" 
                                                    videoSrc={video.videoSrc} 
                                                    playbackStartDelay={1000}
                                                    pausedOverlay={                                        
                                                        <img
                                                            src={video.thumbnail}
                                                            alt="thumnailVideo"
                                                            className="thumnailVideo"
                                                            style={{
                                                                objectFit: 'cover'                                           
                                                            }}
                                                        />
                                                    }
                                                    muted={false}
                                                    unloadVideoOnPaused
                                                />
                                            </Link> 
                                            <div className="gridimg"><Link className="linkTxtCursor" to={`/video/${encode(video.id+'||'+video.party_rank+'||'+props.title+'||'+props.subtitle+'||'+props.category+'||home')}`}><img className="mobile-thumb" src={video.thumbnail} alt="thumnail-mbile" /></Link></div>
                                            <MobileButtons button={video} rank={video.party_rank} subtitle={props.subtitle}/>
{console.log(video.party_rank)}
                                            <div className={`hideMobileView boxInner ${props.rank === '0' ? "" : "companyBox"}`} category={props.category}>
                                                <div className="textVideo">
                                                    <span className="showHover">{video.date} <span className="dottedViews">.</span> {video.type === 'video' ? video.pv_views : video.hits}  Views</span>                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        
                                : 
                                    (
                                        <div className="hoverBox">
                                            <div className="hoverBoxInner">
                                                <div className="gridimg">
                                                    <a href={`${video.url}`} target='_blank' rel="noopener noreferrer">
                                                        <img src={video.image} alt={video.title} className="articleimg" />
                                                    </a>
                                                </div>
                                                <MobileButtons button={video} rank={video.party_rank} subtitle={props.subtitle}/> 

                                                <div className={`hideMobileView boxInner ${props.rank === '0' ? "" : "companyBox"}`}>
                                                    <div className="textVideo">
                                                        <span className="showHover">{video.date} <span className="dottedViews">.</span> {video.hits}  Views</span>                                
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )
                    )
                
                }
            </Carousel>                
        </div>
    </div>
    )
};

export default ArticleVideoGrid;
