import React from 'react';
import website from '../img/website 2.png';
import vb from '../img/VB-Logo-white.png';
import envelop from '../img/Email-cnt 2.png';
import linkedin from '../img/Linkedin-white.png';
import TagManager from 'react-gtm-module';

const AdsComponent = (props) => {


    const tagManagerpopupads = {
        dataLayer: {
            event: 'Banner',
            eventCategory: 'ReelsLEADERBOARDADViews',
            //eventAction: props.adsData[0].title,
            eventAction: '/',
            eventLabel: props.adsData[0].organizerPim,
        }
    }
    TagManager.initialize(tagManagerpopupads);

    const tagManagerreadmoreads1 = {
        dataLayer: {
            event: 'Banner',
            eventCategory: 'ReelsDEFAULTREADMOREADViews',
            //eventAction: props.adsData[1].title,
            eventAction: '/',
            eventLabel: props.adsData[1].organizerPim,
        }
    }
    TagManager.initialize(tagManagerreadmoreads1);

    const tagManagerreadmoreads2 = {
        dataLayer: {
            event: 'Banner',
            eventCategory: 'ReelsDEFAULTREADMOREADViews',
            //eventAction: props.adsData[2].title,
            eventAction: '/',
            eventLabel: props.adsData[2].organizerPim,
        }
    }
    TagManager.initialize(tagManagerreadmoreads2);

    const setClickEvent = (evntCat, evntAct, evntLabel) => {
        const tagManagerClick = {
            dataLayer: {
                event: 'Banner',
                eventCategory: evntCat,
                eventAction: evntAct,
                eventLabel: evntLabel,
            }
        }
        TagManager.initialize(tagManagerClick);
    };
    

    return (
        <div className="adsMainSection">
            <div className="adsSection">
                <div className="adsSectionIn">
                <div className="readMoreAdLeft">
                    <div className="rmadsSubTitle">{props.adsData[1].title}</div> 
                </div>                     
                <div className="popUpAd">
                    <div className="rmadsSubTitle">{props.adsData[0].title}</div>
                </div> 
                <div className="readMoreAdRight">
                    <div className="rmadsSubTitle lstTitle">{props.adsData[2].title}</div>
                </div> 
                </div>
                <div className='clearfix'></div>
                <div className="readMoreAdLeft">
                   
                    <div className="rmads">
                        {props.adsData[1].imgSrc === '' ? 
                            (
                                <a href={props.adsData[1].link} target='_blank' rel="noopener noreferrer" onClick={() => {setClickEvent('ReelsDEFAULTREADMOREADClicks', props.adsData[1].organizerPim, '/')}} >{props.adsData[1].adButler}</a>
                            )
                        : 
                            (
                                <a href={props.adsData[1].link} target='_blank' rel="noopener noreferrer" onClick={() => {setClickEvent('ReelsDEFAULTREADMOREADClicks', props.adsData[1].organizerPim, '/')}}><img className="adsImg readmoreAds" src={props.adsData[1].imgSrc} alt={props.adsData[1].title} /></a>
                            )
                        }
                    </div>
                    <div className="linkhref linkRm deskLikns">
                        <a className={`${props.adsData[1].bVirBooth === '' ? "noLinkExists" : ""}`} href={props.adsData[1].bVirBooth} target='_blank' rel="noopener noreferrer"><img src={vb} alt="VB" /> <span className="txtLnk">Virtual Booth</span></a>
                        <a onClick={() => {setClickEvent('ReelsClientWebsiteClick', props.adsData[1].organizerPim, '/')}} className={`singleLine ${props.adsData[1].link === '' ? "noLinkExists" : ""}`} href={props.adsData[1].link} target='_blank' rel="noopener noreferrer"><img src={website} alt="Website" /> <span className="txtLnk">Website</span></a>
                        <a onClick={() => {setClickEvent('ReelsClientEmailClick', props.adsData[1].organizerPim, props.adsData[1].email)}} className={`singleLine ${props.adsData[1].email === '' ? "noLinkExists" : ""}`} href={`mailto:${props.adsData[1].email}`} target='_blank' rel="noopener noreferrer"><img src={envelop} alt="Email" /> <span className="txtLnk">Email</span></a>
                        <a className={`singleLine lastLink ${props.adsData[1].linkden === '' ? "noLinkExists" : ""}`} href={props.adsData[1].linkden} target='_blank' rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /> <span className="txtLnk">LinkedIn</span></a>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="popUpAd">
                    
                    <div className="adsPop">
                        {props.adsData[0].imgSrc === '' ? 
                            (
                                <a href={props.adsData[0].link} target='_blank' rel="noopener noreferrer" onClick={() => {setClickEvent('ReelsLEADERBOARDADClicks', props.adsData[0].organizerPim, '/')}}>{props.adsData[0].adButler}</a>
                            )
                        : 
                            (
                                <a href={props.adsData[0].link} target='_blank' rel="noopener noreferrer" onClick={() => {setClickEvent('ReelsLEADERBOARDADClicks', props.adsData[0].organizerPim, '/')}}><img className="adsImg popAds" src={props.adsData[0].imgSrc} alt={props.adsData[0].title} /></a>
                            )
                        }  
                    </div>
                    <div className="linkhref linkRm deskLikns">
                        <a className={`${props.adsData[0].bVirBooth === '' ? "noLinkExists" : ""}`} href={props.adsData[0].bVirBooth} target='_blank' rel="noopener noreferrer"><img src={vb} alt="VB" /> <span className="txtLnk">Virtual Booth</span></a>
                        <a onClick={() => {setClickEvent('ReelsClientWebsiteClick', props.adsData[0].organizerPim, '/')}} className={`singleLine ${props.adsData[0].link === '' ? "noLinkExists" : ""}`} href={props.adsData[0].link} target='_blank' rel="noopener noreferrer"><img src={website} alt="Website" /> <span className="txtLnk">Website</span></a>
                        <a onClick={() => {setClickEvent('ReelsClientEmailClick', props.adsData[0].organizerPim, props.adsData[0].email)}} className={`singleLine ${props.adsData[0].email === '' ? "noLinkExists" : ""}`} href={`mailto:${props.adsData[0].email}`} target='_blank' rel="noopener noreferrer"><img src={envelop} alt="Email" /> <span className="txtLnk">Email</span></a>
                        <a className={`singleLine lastLink ${props.adsData[0].linkden === '' ? "noLinkExists" : ""}`} href={props.adsData[0].linkden} target='_blank' rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /> <span className="txtLnk">LinkedIn</span></a>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="readMoreAdRight">
                    
                    <div className="">
                        {props.adsData[2].imgSrc === '' ? 
                            (
                                <a href={props.adsData[2].link} target='_blank' rel="noopener noreferrer" onClick={() => {setClickEvent('ReelsDEFAULTREADMOREADClicks', props.adsData[2].organizerPim, '/')}}>{props.adsData[2].adButler}</a>
                            )
                        : 
                            (
                                <a href={props.adsData[2].link} target='_blank' rel="noopener noreferrer" onClick={() => {setClickEvent('ReelsDEFAULTREADMOREADClicks', props.adsData[2].organizerPim, '/')}}><img className="adsImg readmoreAds" src={props.adsData[2].imgSrc} alt={props.adsData[2].title} /></a>
                            )
                        }
                        <div className="linkhref linkRm deskLikns">
                            <a className={`${props.adsData[2].bVirBooth === '' ? "noLinkExists" : ""}`} href={props.adsData[2].bVirBooth} target='_blank' rel="noopener noreferrer"><img src={vb} alt="VB" /> <span className="txtLnk">Virtual Booth</span></a>
                            <a onClick={() => {setClickEvent('ReelsClientWebsiteClick', props.adsData[2].organizerPim, '/')}} className={`singleLine ${props.adsData[2].link === '' ? "noLinkExists" : ""}`} href={props.adsData[2].link} target='_blank' rel="noopener noreferrer"><img src={website} alt="Website" /> <span className="txtLnk">Website</span></a>
                            <a onClick={() => {setClickEvent('ReelsClientEmailClick', props.adsData[2].organizerPim, props.adsData[2].email)}} className={`singleLine ${props.adsData[2].email === '' ? "noLinkExists" : ""}`} href={`mailto:${props.adsData[2].email}`} target='_blank' rel="noopener noreferrer"><img src={envelop} alt="Email" /> <span className="txtLnk">Email</span></a>
                            <a className={`singleLine lastLink ${props.adsData[2].linkden === '' ? "noLinkExists" : ""}`} href={props.adsData[2].linkden} target='_blank' rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /> <span className="txtLnk">LinkedIn</span></a>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    );
};  

export default AdsComponent;