// StateContext.js
import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export function StateProvider({ children }) {
    const [subscribeModal, setSubscribeModal] = useState(false)

  const updateState = (newState) => {
    setSubscribeModal(newState);
  };

  return (
    <StateContext.Provider value={{ subscribeModal, updateState }}>
      {children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  return useContext(StateContext);
}
