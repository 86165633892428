import React from "react";
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Homepage from "./Pages/Homepage";
import CategoryVideo from "./Pages/Category";
import WatchVideo from "./Pages/WatchVideo";
import Search from "./Pages/Search";
import VideoPage from "./Pages/VideoPage";


function App() {
	return (
		<>
			{/*for demo  */}
			{/* <Routes>
				<Route path="/pharmareels" element={<Homepage />} />
				<Route path="/pharmareels/category/:catId" element={<CategoryVideo />} />
				<Route path="/pharmareels/watch/:page/:videoId" element={<WatchVideo />} />
				<Route path="/pharmareels/search" element={<Search />} />
				<Route path="/pharmareels/video/:videoId" exact element={<VideoPage />} />
			</Routes> */}

			{/* for live */}
			<Routes>
				<Route path="/" element={<Homepage />} />
				<Route path="/category/:catId" element={<CategoryVideo />} />
				<Route path="/watch/:page/:videoId" element={<WatchVideo />} />
				<Route path="/search" element={<Search />} />
				<Route path="/video/:videoId" exact element={<VideoPage />} />
			</Routes>

		</>
	);
}

export default App;
